import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BackDialogComponent } from 'src/app/components/back-dialog/back-dialog.component';

import { CanComponentDeactivate } from 'src/app/service/candeactivate.guard';

import { ServiceService } from 'src/app/service/service.service';
import { IProfile } from 'src/app/shares/models/interface-auth';
import { AuthService } from 'src/app/service/auth.service';
import { CustomInputService } from './custom-input.service';
import { edcBank } from 'src/app/shares/models/interface-edc';
import { DragndropserviceService } from 'src/app/shares/dragndrop/dragndropservice.service';
import { MatPhoto, PhotoMapping } from 'src/app/shares/models/interface-photo';
import { Router, UrlTree } from '@angular/router';
import { Observable, Subscription, interval, map, startWith } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-merchant',
  templateUrl: './add-merchant.component.html',
  styleUrls: ['./add-merchant.component.css'],
})
export class AddMerchantComponent
  implements OnInit, CanComponentDeactivate, OnDestroy
{
  @ViewChild('firstInvalidControl') firstInvalidControl!: ElementRef;
  myForm!: FormGroup;
  filteredOptionProvince?: Observable<string[]>;
  filteredOptionDistrict?: Observable<string[]>;
  constructor(
    private fb: FormBuilder,
    private el: ElementRef,
    private router: Router,
    private service: ServiceService,
    private authServuce: AuthService,
    private inputService: CustomInputService,
    private dragndropService: DragndropserviceService,
    private http: HttpClient,
    public dialog: MatDialog
  ) {}

  data: any = {};
  formManagement: any = [];
  totalCountry: any;
  countryId: any;
  country: any;
  user?: IProfile | null;
  formId: any;
  categoryInputRequired: boolean = false;
  locationNameInputRequired: boolean = false;
  isContactlessInput: boolean = false;
  isCompetitionInfo: boolean = false;
  locationLoading: boolean = false;
  isPhotoValidArray: boolean[] = [];
  terminalIdRequired: boolean = false;
  isLoading: boolean = false;
  errorMessage?: string;
  isSuccess = false;
  subscriptionAll!: Subscription;
  testInterval!: Subscription;
  test: any;
  ngOnInit() {
    this.initForm();
    this.user = this.authServuce.getProfile();
    this.getCountry();
    this.Getprovince();
    this.Getdistrict();
    this.filteredOptionProvince = this.myForm
      .get('province')
      ?.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterProvince(value || ''))
      );
    this.filteredOptionDistrict = this.myForm
      .get('district')
      ?.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterDistrict(value || ''))
      );

    this.initSubscription();
  }

  private _filterProvince(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.provinces?.filter((option: any) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  private _filterDistrict(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.district?.filter((option: any) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  initSubscription() {
    this.subscriptionAll = this.inputService.CardAcceptanceUpdated.subscribe(
      (resultList: string[]) => {
        this.myForm.get('cardAcceptance')?.setValue(resultList);
      }
    );

    const sub2 = this.inputService.OtherPaymentUpdated.subscribe(
      (resultList: string[]) => {
        this.myForm.get('otherPayment')?.setValue(resultList);
      }
    );

    this.subscriptionAll.add(sub2);

    const sub3 = this.inputService.CompetitionUpdated.subscribe(
      (resultList: string[]) => {
        this.myForm.get('competitorBank')?.setValue(resultList);
      }
    );

    this.subscriptionAll.add(sub3);

    const sub4 = this.dragndropService.edcNormalPhotoUpdated.subscribe(
      (resultList: File[]) => {
        this.myForm.get('edcNormalPhoto')?.setValue(resultList);
      }
    );

    this.subscriptionAll.add(sub4);

    const sub5 = this.dragndropService.contactPhotoPhotoUpdated.subscribe(
      (resultList: File[]) => {
        this.myForm.get('contactPhoto')?.setValue(resultList);
      }
    );

    this.subscriptionAll.add(sub5);

    const sub6 = this.dragndropService.CompetitionPhotoUpdated.subscribe(
      (resultList: File[]) => {
        this.myForm.get('competitorPhoto')?.setValue(resultList);
      }
    );

    this.subscriptionAll.add(sub6);

    const sub7 = this.dragndropService.materialPhotoPhotoUpdated.subscribe(
      (resultList: File[]) => {
        this.myForm.get('materialPhoto')?.setValue(resultList);
      }
    );

    this.subscriptionAll.add(sub7);

    const sub8 =
      this.dragndropService.customizedMaterialPhotoPhotoUpdated.subscribe(
        (resultList: File[]) => {
          this.myForm.get('customizeMaterialPhoto')?.setValue(resultList);
        }
      );

    this.subscriptionAll.add(sub8);
  }

  initForm() {
    this.myForm = this.fb.group({
      merchantName: '',
      category: '',
      newCategory: '',
      locationName: '',
      newLocationName: '',
      isOnStreet: false,
      shopPhoneNumber: '',
      street: '',
      floor: '',
      district: '',
      province: '',
      zipcode: '',
      latitude: '',
      longitude: '',
      contactName: '',
      phoneNumber: '',
      email: '',
      fax: '',
      cardAcceptance: '',
      minimumPayment: '',
      no_minimumPayment: [false],
      surcharge: '',
      no_surcharge: [false],
      otherPayment: '',
      edcData: this.fb.array([
        this.fb.group({
          edcBank: [''],
          edcTerminalId: [''],
          isNormalEDC: [false],
          edcNormalIssue: [''],
          isContactAcceptance: [false],
          isContactEDC: [false],
          contactIssue: [''],
          contactPlacement: [''],
          staffPerformance: [''],
          isContactlessTraining: [''],
          isWireless: [''],
          no_TerminalId: [false],
        }),
      ]),
      edcNormalPhoto: '',
      contactPhoto: '',
      materialQty: this.fb.array([]),
      materialPhoto: '',
      materialCustomQty: this.fb.array([]),
      customizeMaterialPhoto: '',
      isCompetitionInfo: [false],
      competitorBank: '',
      competitorPhoto: '',
    });
  }
  initMaterialQty() {
    for (let i = 0; i < this.formManagement.length; i++) {
      if (
        this.formManagement[i].fieldName === 'General Material' &&
        this.formManagement[i].isShow
      ) {
        const matQty = this.formManagement[i].choices;
        const matQtyMapping = matQty.forEach((item: any) => {
          const materialQty = this.fb.group({
            key: item,
            value: 0,
            isValue: false,
          });
          this.materialQty.push(materialQty);
        });
      }
      if (
        this.formManagement[i].fieldName === 'Customize Material' &&
        this.formManagement[i].isShow
      ) {
        const matQty = this.formManagement[i].choices;
        const matQtyMapping = matQty.forEach((item: any) => {
          const materialCustomQty = this.fb.group({
            key: item,
            value: 0,
            isValue: false,
          });
          this.materialCustomQty.push(materialCustomQty);
        });
      }
    }
  }

  get edcData() {
    return this.myForm.controls['edcData'] as FormArray;
  }

  addEdcData() {
    const edcData = this.fb.group({
      edcBank: [''],
      edcTerminalId: [''],
      isNormalEDC: [false],
      edcNormalIssue: [''],
      isContactAcceptance: [false],
      isContactEDC: [false],
      contactIssue: [''],
      contactPlacement: [''],
      staffPerformance: [''],
      isContactlessTraining: [''],
      isWireless: [''],
      no_TerminalId: [false],
    });
    this.edcData.push(edcData);
    this.toggleContactlessInput();
  }

  deleteEdcData(edcIndex: number) {
    this.edcData.removeAt(edcIndex);
    this.toggleContactlessInput();
  }

  get materialQty() {
    return this.myForm.controls['materialQty'] as FormArray;
  }

  get materialCustomQty() {
    return this.myForm.controls['materialCustomQty'] as FormArray;
  }

  choiceMaterial: string = '';
  choiceQty = 0;
  choiceCustomMaterial: string = '';
  choiceCustomQty = 0;
  addMaterialQty(subject: string) {
    if (subject === 'matQty') {
      const materialQty = this.fb.group({
        key: this.choiceMaterial,
        value: this.choiceQty,
        isValue: true,
      });
      this.materialQty.push(materialQty);
      this.choiceMaterial = '';
      this.choiceQty = 0;
    } else if (subject === 'customMatQty') {
      const materialQty = this.fb.group({
        key: this.choiceCustomMaterial,
        value: this.choiceCustomQty,
        isValue: true,
      });
      this.materialCustomQty.push(materialQty);
      this.choiceCustomMaterial = '';
      this.choiceCustomQty = 0;
    }
  }

  handleAdd(index: any, subject: string) {
    if (subject === 'matQty') {
      this.materialQty.value[index].value++;
    } else if (subject === 'customMatQty') {
      this.materialCustomQty.value[index].value++;
    }
  }
  handleMinus(index: any, subject: string) {
    if (subject === 'matQty') {
      const currentValue = this.materialQty.value[index].value;
      if (currentValue > 1) {
        this.materialQty.value[index].value--;
      }
    } else if (subject === 'customMatQty') {
      const currentValue = this.materialCustomQty.value[index].value;
      if (currentValue > 1) {
        this.materialCustomQty.value[index].value--;
      }
    }
  }

  handleAddNewMaterial(subject: string) {
    if (subject === 'matQty') {
      this.choiceQty++;
    } else if (subject === 'customMatQty') {
      this.choiceCustomQty++;
    }
  }
  handleMinusNewMaterial(subject: string) {
    if (subject === 'matQty') {
      if (this.choiceQty > 0) {
        this.choiceQty--;
      }
    } else if (subject === 'customMatQty') {
      this.choiceCustomQty--;
    }
  }

  toggleCategoryInputRequired(selectedValue: string) {
    // Set the required attribute based on the selected category value
    this.categoryInputRequired = selectedValue === 'Others';
  }

  toggleLocationNameInputRequired(selectedValue: string) {
    // console.log(this.formManagement[2].choices.name);
    for (let choice of this.formManagement[2].choices) {
      if (choice.name === selectedValue) {
        this.locationNameInputRequired = false;

        this.myForm.get('district')?.setValue(choice.district);
        this.myForm.get('province')?.setValue(choice.province);
        this.myForm.get('zipcode')?.setValue(choice.zipcode);
      }
      if (selectedValue === 'Others' || selectedValue === '') {
        // Set the required attribute based on the selected category value
        this.locationNameInputRequired = selectedValue === 'Others';

        this.myForm.get('district')?.setValue('');
        this.myForm.get('province')?.setValue('');
        this.myForm.get('zipcode')?.setValue('');
      }
    }
  }
  toggleContactlessInput() {
    const edcArray = this.edcData.value;
    this.isContactlessInput = edcArray.some((item: any) => {
      return item.isContactAcceptance;
    });
    if (!this.isContactlessInput) {
      this.myForm.get('contactPhoto')?.setValue('');
    }
  }

  toggleIsContactAcceptance(value: any, index: number) {
    this.toggleContactlessInput();
    if (!value) {
      this.edcData.at(index).get('isContactEDC')?.setValue(false);
      this.edcData.at(index).get('contactBank')?.setValue('');
      this.edcData.at(index).get('contactIssue')?.setValue('');
      this.edcData.at(index).get('contactPlacement')?.setValue('');
      this.edcData.at(index).get('staffPerformance')?.setValue('');
    }
  }
  toggleIsCompetitionInfo(value: any) {
    this.isCompetitionInfo = value;
  }

  async getCountry() {
    let data: any = await this.service.GetTokenPathParams(
      '/backoffice/form/filter?search=&skip=1&limit=10'
    );
    this.totalCountry = data.totalData;
    this.reorderCountries();

    for (let item of this.totalCountry) {
      if (item.country === 'Thailand') {
        this.countryId = item._id;
        this.country = item.country;
      }
    }
    await this.getFormmanagement(this.countryId, true);
  }

  reorderCountries() {
    this.totalCountry?.sort((a: any, b: any) =>
      a.country === 'Thailand' ? -1 : b.country === 'Thailand' ? 1 : 0
    );
  }

  async getFormId(event: any) {
    const formId = event;
    this.countryId = formId;
    const getCountry = await this.totalCountry.filter(
      (item: any) => item._id === event
    );

    this.country = getCountry[0].country;

    await this.getFormmanagement(formId);
    await this.clearFromChangeForm();
    this.addEdcData();
    this.initMaterialQty();
  }
  async clearFromChangeForm() {
    this.materialQty.clear();
    this.materialCustomQty.clear();
    this.edcData.clear();
    this.myForm.get('cardAcceptance')?.reset('');
    this.myForm.get('qrPay')?.reset('');
    this.myForm.get('eWallet')?.reset('');
    this.myForm.get('competitorBank')?.reset('');
    this.myForm.get('edcNormalPhoto')?.reset('');
    this.myForm.get('contactPhoto')?.reset('');
    this.myForm.get('materialPhoto')?.reset('');
    this.myForm.get('customizeMaterialPhoto')?.reset('');
    this.myForm.get('competitorPhoto')?.reset('');
  }

  async getFormmanagement(formId: any, isInitial?: boolean) {
    this.formId = formId;

    // let data: any = await this.service.GetTokenPathParams('/backoffice/form/' + this.formId)
    let data: any = await this.service.GetTokenPathParams(
      '/backoffice/form/' + this.formId
    );
    this.formManagement = data.formElement;

    console.log(this.formManagement);

    if (isInitial) {
      this.initMaterialQty();
    }
  }
  provinces: any;
  async Getprovince() {
    let url: any =
      'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json';
    this.http.get(url).subscribe((res) => {
      if (Array.isArray(res)) {
        this.provinces = res.map((item: any) => {
          return item.name_th;
        });
      }
    });
  }
  district: any;
  async Getdistrict() {
    let url: any =
      'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json';
    this.http.get(url).subscribe((res) => {
      if (Array.isArray(res)) {
        this.district = res.map((item: any) => {
          return item.name_th;
        });
      }
    });
  }

  // Method to check if the cardAcceptance control has a value
  isCardAcceptanceControlValid(name: string): boolean {
    if (name === 'competitorBank') {
      const result =
        this.myForm.get(name)?.value &&
        this.myForm.get(name)?.value.length > 0 &&
        this.isCompetitionInfo;

      if (result) {
        return result;
      } else {
        return false;
      }
    } else {
      const result =
        this.myForm.get(name)?.value && this.myForm.get(name)?.value.length > 0;

      if (result) {
        return result;
      } else {
        return false;
      }
    }
  }

  error: any;

  isPhotoValid(event: boolean, index: number) {
    if (this.isPhotoValidArray.length === 0) {
      for (let i = 0; i <= index; i++) {
        this.isPhotoValidArray.push(event);
      }
    } else {
      this.isPhotoValidArray[index] = event;
    }

    const isPhotoInValid = this.isPhotoValidArray.includes(false);
    if (!isPhotoInValid) {
      this.myForm.get('materialPhoto')?.setValue(true);
    } else if (isPhotoInValid) {
      this.myForm.get('materialPhoto')?.setValue('');
    }
  }

  coordinates: any = [];
  Getlocation() {
    this.locationLoading = true;
    navigator.geolocation.getCurrentPosition((position) => {
      this.coordinates.push({ lat: position.coords.latitude });
      this.coordinates.push({ long: position.coords.longitude });
      if (position.coords.latitude && position.coords.latitude) {
        this.locationLoading = false;
        this.data.lat = position.coords.latitude;
        this.data.long = position.coords.longitude;
        this.myForm.controls['latitude'].setValue(position.coords.latitude);
        this.myForm.controls['longitude'].setValue(position.coords.longitude);
      }
    });
  }

  onlyNumber(event: any, subject: string) {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, '');

    this.myForm.get(subject)?.setValue(numericValue);
  }

  toggleDisable(subject: any, index?: any) {
    if (subject === 'no_minimumPayment') {
      const noMinimumValue = this.myForm.get(subject)?.value;
      if (noMinimumValue) {
        this.myForm.get('minimumPayment')?.disable();
      } else if (!noMinimumValue) {
        this.myForm.get('minimumPayment')?.enable();
      }
    } else if (subject === 'no_surcharge') {
      const noSurcharge = this.myForm.get(subject)?.value;
      if (noSurcharge) {
        this.myForm.get('surcharge')?.disable();
      } else if (!noSurcharge) {
        this.myForm.get('surcharge')?.enable();
      }
    } else if (subject === 'no_TerminalId') {
      const edcDataArray = this.myForm.get('edcData') as FormArray;
      const edcData = edcDataArray.at(index) as FormGroup;
      const isNoTerminalId = edcData.get('no_TerminalId')?.value;

      if (isNoTerminalId) {
        edcData.get('edcTerminalId')?.disable();
      } else if (!isNoTerminalId) {
        edcData.get('edcTerminalId')?.enable();
      }
    } else if (subject === 'isMatQty') {
      const materialQtyArray = this.myForm.get('materialQty') as FormArray;
      const materialQty = materialQtyArray.at(index) as FormGroup;
      const isMatQty = materialQty.get('isValue')?.value;

      if (!isMatQty) {
        materialQty.get('value')?.setValue(0);
      } else {
        materialQty.get('value')?.setValue(1);
      }
    } else if (subject === 'isCustomMatQty') {
      const materialQtyArray = this.myForm.get(
        'materialCustomQty'
      ) as FormArray;
      const materialQty = materialQtyArray.at(index) as FormGroup;
      const isMatQty = materialQty.get('isValue')?.value;

      if (!isMatQty) {
        materialQty.get('value')?.setValue(0);
      } else {
        materialQty.get('value')?.setValue(1);
      }
    }
  }

  appendPhotosToFormData(photoKey: string, photos: File[], formData: FormData) {
    if (photos.length > 0) {
      for (let photoIndex in photos) {
        formData.append(photoKey, photos[photoIndex]);
      }
    }
  }

  async formSummary() {
    const categoryInputValue = this.myForm.get('category')?.value;
    if (categoryInputValue === 'Others') {
      this.myForm
        .get('category')
        ?.setValue(this.myForm.get('newCategory')?.value);
    }
    const locationInputValue = this.myForm.get('locationName')?.value;
    if (locationInputValue === 'Others') {
      this.myForm
        .get('locationName')
        ?.setValue(this.myForm.get('newLocationName')?.value);
    }

    const edcDataArray = this.myForm.value.edcData;

    const extractedEdcData = edcDataArray.map(
      ({
        edcBank,
        edcTerminalId,
        isNormalEDC,
        edcNormalIssue,
        isContactAcceptance,
        isContactEDC,
        contactIssue,
        contactPlacement,
        staffPerformance,
        isContactlessTraining,
        isWireless,
      }: any) => ({
        edcBank,
        edcTerminalId: edcTerminalId || '',
        isNormalEDC,
        edcNormalIssue: edcNormalIssue || '',
        isContactAcceptance,
        isContactEDC,
        contactIssue: contactIssue || '',
        contactPlacement: contactPlacement || '',
        staffPerformance: staffPerformance || '',
        isContactlessTraining,
        isWireless,
      })
    );
    const materialQtyArray = this.myForm.value.materialQty;

    const extractedMaterialQty = materialQtyArray.map(
      ({ key, value }: any) => ({
        key,
        value,
      })
    );

    const customMaterialQtyArray = this.myForm.value.materialCustomQty;

    const extractedCustomMaterialQty = customMaterialQtyArray.map(
      ({ key, value }: any) => ({
        key,
        value,
      })
    );

    const formSummary = {
      merchantName: this.myForm.value.merchantName,
      category: this.myForm.value.category,
      locationName: this.myForm.value.locationName,
      isOnStreet: this.myForm.value.isOnStreet,
      shopPhoneNumber: this.myForm.value.shopPhoneNumber,
      street: this.myForm.value.street,
      floor: this.myForm.value.floor,
      district: this.myForm.value.district,
      province: this.myForm.value.province,
      zipcode: this.myForm.value.zipcode,
      latitude: this.myForm.value.latitude
        ? JSON.stringify(this.myForm.value.latitude)
        : '0',
      longitude: this.myForm.value.latitude
        ? JSON.stringify(this.myForm.value.longitude)
        : '0',
      contactName: this.myForm.value.contactName,
      phoneNumber: this.myForm.value.phoneNumber,
      email: this.myForm.value.email,
      fax: this.myForm.value.fax,
      cardAcceptance:
        this.myForm.value.cardAcceptance.length > 0
          ? JSON.stringify(this.myForm.value.cardAcceptance)
          : JSON.stringify([]),
      minimumPayment: this.myForm.value.minimumPayment
        ? this.myForm.value.minimumPayment
        : '0',
      no_minimumPayment: !(Number(this.myForm.value.minimumPayment) > 0)
        ? true
        : this.myForm.value.no_minimumPayment,
      surcharge: this.myForm.value.surcharge
        ? this.myForm.value.surcharge
        : '0',
      no_surcharge: !(Number(this.myForm.value.surcharge) > 0)
        ? true
        : this.myForm.value.no_surcharge,
      otherPayment:
        this.myForm.value.otherPayment.length > 0
          ? JSON.stringify(this.myForm.value.otherPayment)
          : JSON.stringify([]),
      edcData: JSON.stringify(extractedEdcData),
      materialQty: JSON.stringify(extractedMaterialQty),
      materialCustomQty: JSON.stringify(extractedCustomMaterialQty),
    };
    console.log(formSummary);

    const formData = new FormData();
    for (const [key, value] of Object.entries(formSummary)) {
      formData.append(key, value);
    }

    this.appendPhotosToFormData(
      'edcNormalPhoto',
      this.myForm.value.edcNormalPhoto,
      formData
    );
    this.appendPhotosToFormData(
      'contactPhoto',
      this.myForm.value.contactPhoto,
      formData
    );
    this.appendPhotosToFormData(
      'generalMaterialPhoto',
      this.myForm.value.materialPhoto,
      formData
    );
    this.appendPhotosToFormData(
      'customizeMaterialPhoto',
      this.myForm.value.customizeMaterialPhoto,
      formData
    );

    return formData;
  }

  async formCompetitorSummary() {
    const competitorBank = this.myForm.value.competitorBank;
    const competitorPhoto = this.myForm.value.competitorPhoto;

    if (this.isCompetitionInfo) {
      if (competitorBank.length > 0 || competitorPhoto.length > 0) {
        const competitorBankListToJson =
          competitorBank.length > 0
            ? JSON.stringify(competitorBank)
            : JSON.stringify([]);

        const formDataCompitetor = new FormData();
        formDataCompitetor.append('competitorBank', competitorBankListToJson);

        this.appendPhotosToFormData(
          'competitorPhoto',
          competitorPhoto,
          formDataCompitetor
        );

        return formDataCompitetor;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  acceptSubmit() {
    setTimeout(() => {
      this.onSubmit();
    }, 500);
  }

  async onSubmit() {
    console.log(this.myForm.value);

    this.errorMessage = '';
    this.isLoading = false;
    if (this.myForm.invalid) {
      this.errorMessage = 'Please complete the form!';
      this.myForm.markAllAsTouched();
      const firstInvalidControl: HTMLElement =
        this.el.nativeElement.querySelector('.ng-invalid[formControlName]');

      firstInvalidControl.focus();
    } else {
      this.isLoading = true;
      const formData = await this.formSummary();

      if (formData) {
        const data: any = await this.service.PostTokenPathBody(
          '/backoffice/merchant/' + this.formId,
          formData
        );
        console.log(data);

        if (data.id) {
          const formDataCompitetor = await this.formCompetitorSummary();
          console.log(formDataCompitetor);

          if (formDataCompitetor) {
            const res: any = await this.service.PostTokenPathBody(
              '/backoffice/merchant/competitor/' + data.id,
              formDataCompitetor
            );
            console.log('response::', res);
            if (res.msg === 'Success') {
              this.isSuccess = true;
              document.getElementById('btnsuccess')?.click();
            } else {
              if (res.error.message) {
                this.errorMessage = data.error.message;
                this.isLoading = false;
              } else {
                this.errorMessage =
                  'Something went wrong, can not create the competitor!';
                this.isLoading = false;
              }
            }
          } else {
            this.isLoading = false;
            this.isSuccess = true;
            document.getElementById('btnsuccess')?.click();
          }
        } else {
          if (data.error.message) {
            this.errorMessage = data.error.message;
            this.isLoading = false;
          } else {
            this.errorMessage = 'Something went wrong, please try again later!';

            this.isLoading = false;
          }
        }
      }
    }
  }

  openDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(BackDialogComponent, {
      disableClose: true,
    });

    return dialogRef.afterClosed();
  }

  back() {
    setTimeout(() => {
      this.router.navigate(['/database']);
    }, 500);
  }

  ngOnDestroy(): void {
    this.subscriptionAll.unsubscribe();
  }

  canDeactivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this.myForm.dirty && !this.isSuccess) {
      return this.openDialog();
    } else {
      return true;
    }
  }
}
