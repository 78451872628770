<div class="container-fluid">
  <div class="card" style="margin: auto" *ngIf="flagLogin">
    <div class="card-title text-center">
      <h1>VTHG POS System</h1>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 mb-3">
          <p>Email</p>
          <input
            type="text"
            class="form-control"
            placeholder="Fill your email"
            [(ngModel)]="email"
            (keyup.enter)="Login()"
          />
        </div>
        <div class="col-md-12 mb-4">
          <p>Password</p>
          <input
            type="password"
            class="form-control"
            placeholder="Fill your password"
            [(ngModel)]="password"
            (keyup.enter)="Login()"
          />
        </div>
        <div class="col-md-12 text-danger">
          <p *ngIf="alert.message">{{ alert?.message }}</p>
        </div>
        <div class="col-md-12 mb-4">
          <button class="btnsave w-100" (click)="Login()" *ngIf="!isLoading">
            Login
          </button>
          <button class="btnsave w-100" [disabled]="true" *ngIf="isLoading">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
        <div class="col-md-12 mb-3 text-center">
          <a (click)="changeFlagLogin()">Forgot password</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card forDesktop" style="margin: auto" *ngIf="!flagLogin">
    <div class="row">
      <div class="col-md-1">
        <button class="btncancel" (click)="changeFlagLogin()">
          <span class="material-symbols-outlined"> chevron_left </span>
        </button>
      </div>
      <div class="col-md-10">
        <div class="card-title text-center">
          <h1>Forgot password</h1>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 mb-3">
          <p>Email</p>
          <input
            type="text"
            class="form-control mb-1"
            placeholder="Email"
            [(ngModel)]="email"
          />
          {{
            "Fill an email address to require a change password link"
              | translate
          }}
        </div>
        <div class="col-md-12 text-danger">
          <p *ngIf="alert.message">{{ alert?.message }}</p>
        </div>
        <div class="col-md-12 mt-4">
          <button class="btnsave w-100" (click)="forgotPassword()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper forMobile" *ngIf="!flagLogin">
    <div class="wrapper-title">
      <button class="btncancel" (click)="changeFlagLogin()">
        <span class="material-symbols-outlined"> chevron_left </span>
      </button>
      <h1 class="flag-title">Forget Password</h1>
    </div>
    <div class="wrapper-content">
      <div class="row">
        <div class="col-md-12 mb-3">
          <p>Email</p>
          <input
            type="text"
            class="form-control mb-1"
            placeholder="Email"
            [(ngModel)]="email"
          />
          <p class="fw-lighter">
            {{
              "Fill an email address to require a change password link"
                | translate
            }}
          </p>
        </div>
        <div class="col-md-12 text-danger">
          <p *ngIf="alert.message">{{ alert?.message }}</p>
        </div>
        <div class="col-md-12">
          <button
            class="btnsave w-100 button-custom"
            (click)="forgotPassword()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<button
  id="btnsuccess"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#success"
>
  Launch demo modal
</button>
<!-- Modal success -->
<div class="modal fade" data-bs-backdrop="static" id="success" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-3">
      <div class="row">
        <div class="col-md-12 mb-3 text-center">
          <span
            class="material-symbols-outlined"
            style="font-size: 100px; color: #388087"
          >
            check_circle
          </span>
        </div>
        <div class="col-md-12 text-center">
          <b>{{ "Reset password link has been sent" | translate }}</b> <br />
          {{
            "Please check your inbox for resetting password link" | translate
          }}
        </div>
        <div class="col-md-12 mt-5">
          <button
            data-bs-dismiss="modal"
            class="btnsave w-100"
            (click)="changeFlagLogin()"
          >
            {{ "Done" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
